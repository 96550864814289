import { User } from "oidc-react";
import parseJwt from "./parseJwt";

const isUserAdmin = (user: User | null | undefined) => {
    if (!user) {
        return false;
    }

    const adminGroup = 'AZ-AS-GRP-SG-N-DS-7df2c52618a14fd0adcd';
    const accessToken = user.access_token;
    let isAdmin = false;

    if (accessToken) {
        const token = parseJwt(accessToken);
        if(token) {
            const membership = (token.Groups.join(",").toString() + token.isMemberOf.join(",").toString()).toLowerCase();
            if(membership.indexOf(adminGroup.toLowerCase()) > -1) {
                isAdmin = true;
            }
        }
       
    }
    return isAdmin;
};

export default isUserAdmin;
import { Sentiments } from "@sede-x/shell-ds-react-framework";

const getAlertType = (type: string) => {
  switch (type) {
    case 'information':
      return Sentiments.Information;
    case 'error':
      return Sentiments.Negative;
    case 'warning':
      return Sentiments.Warning;
    case 'success':
      return Sentiments.Positive;
    default:
      return Sentiments.Information;
  }
};

export default getAlertType;
import { Dictionary } from "lodash";
import { IFieldValue } from "../../../models/models";

const generateOptions = (label: string | undefined, allFields: Dictionary<IFieldValue[]> | undefined, systemId: Number) => {

    label = label ?? "";
    const options = allFields ? allFields[label] : null;

    if (options) {
        const selectOptions = options
            .filter((option: IFieldValue) => option.systemId === systemId)
            .map((option: IFieldValue) => {
                return {
                    label: option.value,
                    value: option.value
                };
            });
        selectOptions.sort((a: { label: string, value: string}, b: {label: string, value: string}) => a.label.localeCompare(b.label));
        return selectOptions;
    }
    return [];
};


export default generateOptions;
import { Sizes, Text } from "@sede-x/shell-ds-react-framework";

const SpanElement = ({
  spanTitle,
  spanData,
}: {
  spanTitle: string;
  spanData: string;
}) => {
  return (
    <>
      <Text bold size={Sizes.Small}>{spanTitle}</Text>
      <Text bold>{spanData}</Text>
    </>
  );
};

export default SpanElement;  

const apiConfig = (): any => {

    let headers: any = {
        "Access-Control-Allow-Origin": "*",
    };

    let auth: any = null;

    const authType = process.env.REACT_APP_USE_AUTH;
    let axiosConfig: any = {};

    if (authType === "Basic") {
        const basicCredsData = localStorage.getItem("basicCredsData");
        if (basicCredsData) {
            const basicCreds = JSON.parse(basicCredsData);
            auth = {};
            auth["username"] = basicCreds.username;
            auth["password"] = basicCreds.password;

            headers["apikey"] = basicCreds.apiKey;
            headers["X-Admin-Access-Key"] = basicCreds.adminAccessKey;

            axiosConfig = { headers: headers, auth: auth };
        }
    }


    if (authType === "2FA") {

        const apiTokenData = localStorage.getItem("apiToken");

        if (apiTokenData) {
            const apiToken = JSON.parse(apiTokenData);
            headers["Authorization"] = `Bearer ${apiToken.access_token}`;

            const accessToken = localStorage.getItem("accessToken");
            if(accessToken) {
                headers["Access-Token"] = accessToken;
            }

            axiosConfig = { headers: headers };
        }
    }


    return {
        url: process.env.REACT_APP_API_URL ?? "",
        axiosConfig: axiosConfig        
    }
}

export const getApiConfig = () => { return apiConfig(); };
export type IField = {
  id: number;
  name: string;
  systemId: number;
  systemName: string;
  dataType: string;
  modifiedBy: string;
}

export type IFieldValue = {
  id: number;
  fieldId: number;
  fieldName: string;
  value: string;
  modifiedBy: string;
  systemName: string;
  systemId: number;
}

export type IFieldValueGroup = {
  id: number;
  name: string;
  systemId: number;
  systemName: string;
  modifiedBy: string;
}

export type IFieldValueGroupMapping = {
  id: number;
  fieldValueId: number;
  fieldValue: string;
  fieldName: string;
  fieldId: number;
  systemName: string;
  systemId: number;
  groupId: number;
  groupName: string;
  modifiedBy: string;
}

export type IFieldValueGroupMappingHistory = {
  id: number;
  fieldValueId: number;
  fieldValue: string;
  fieldName: string;
  fieldId: number;
  systemName: string;
  systemId: number;
  groupId: number;
  groupName: string;
  modifiedBy: string;
  validTo: Date;
  validFrom: Date;
  historyChanges: IFieldValueGroupMappingHistory[];
}

export type IGroupMapping = {
  id: number;
  fromGroupId: number;
  fromGroupName: string;
  fromSystemName: string;
  fromSystemId: number;
  toGroupId: number;
  toGroupName: string;
  toSystemName: string;
  toSystemId: number;
  modifiedBy: string;
}

export type IFieldValueGroupMappingRow = {
  id: number;
  fromGroupMappings: IFieldValueGroupMapping[];
  toGroupMappings: IFieldValueGroupMapping[];
}

export type IExchangeModel = {
  id: number;
  name: string;
  mappingGroups: number;
  nextDownload: string;
  details: string;
};

export type System = {
  id: number;
  name: string;
  urlFriendlyName: string;
  modifiedBy: string;
  totalMappingGroups: number;
}  

export type IMappingGroup = {
  groupId: number;
  marketArea: string;
  auctionName: string;
  portfolio: string;
}

export type IRawMappingGroup = {
  id : number;
  fieldValueId : number;
  fieldValue : string;
  fieldName : string;
  fieldId : number;
  systemName : string;
  systemId : number;
  groupId : number;
  groupName : string;
  modifiedBy : string;
}

export type IMappingGroupInfo = {
  noOfFields : number,
  repeatedFields : boolean, 
  fields : Array<string>, 
  groupIds : Array<number>, 
  values : Array<Object>
}

export type IMappingGroupData = {
  mappingGroups: Array<IMappingGroupInfo>;
}

export type ValueItem = {
  label: string,
  value: string
}

export function DefaulValueItem() {
  return {
    label: "",
    value: ""
  } as ValueItem;
}

export type MappingGroup = {
  fromGroupName: string,
  fromGroupId: number,
  fromSystem: System,
  toSystem: System
  toGroupName: string,
  toGroupId: number  
}

export function MappingGroupDefault() {
  return {
    fromGroupName: "",
    fromGroupId: -1,
    fromSystem: {
      id: -1,
      name: "",
      urlFriendlyName: "",
      modifiedBy: "",
      totalMappingGroups: 0
    } as System,
    toSystem: {
      id: -1,
      name: "",
      urlFriendlyName: "",
      modifiedBy: "",
      totalMappingGroups: 0
    } as System,
    toGroupName: "",
    toGroupId: -1
  } as MappingGroup;
}

export type MappingGroupValues = {
  mappingGroup: MappingGroup,
  sourceData: ValueItem[],
  targetData: ValueItem[]
}


export function MappingGroupValuesDefault() {
  return {
    mappingGroup: {
      fromGroupName: "",
      fromSystem: {
        id: -1,
        name: "",
        urlFriendlyName: "",
        modifiedBy: "",
        totalMappingGroups: 0
      },
      toSystem: {
        id: -1,
        name: "",
        urlFriendlyName: "",
        modifiedBy: "",
        totalMappingGroups: 0
      },
      toGroupName: ""
    } as MappingGroup,
    sourceData: [],
    targetData: []
  } as MappingGroupValues;
}

export enum SelectedView  {
  Table = "Table",
  Card = "Card",
}

export type AuthenticatedUser = {
  name: string | null;
  surname: string | null;
  email: string | null;
  groups: string[];
  expires: string | null;
  refreshToken: string | null;
  accessToken: string | null;
};
import axios, { AxiosError } from "axios";
import { IFieldValueGroupMapping, IFieldValueGroupMappingHistory } from "../models/models";
import { useQueries, useQuery } from "react-query";
import { getApiConfig } from "./apiConfig";
import { Dictionary } from "lodash";

export const getFieldValueGroupMappings = async (systemId: number): Promise<IFieldValueGroupMapping[]> => {
  const config = getApiConfig();

  try {
    const response = await axios
      .get(`${config.url}/systems/${systemId}/field-value-group-mappings`, config.axiosConfig);

    return response?.data ?? null;
  } catch (err) {
    console.log(err);
    throw (err);
  }
};

export const getFieldValueGroupMappingsHistory = async (groupId: number): Promise<IFieldValueGroupMappingHistory[]> => {
  const config = getApiConfig();

  try {
    const response = await axios
      .get(`${config.url}/field-value-groups/${groupId}/field-value-group-mappings/history-changes`, config.axiosConfig);

    return response?.data ?? null;
  } catch (err) {
    console.log(err);
    throw (err);
  }
};

export const useGetFieldValueGroupMappings = (systemId: number, dependencyObj: any) => {
  return useQuery<IFieldValueGroupMapping[], AxiosError>({
    queryKey: ["fieldValueGroupMapping-info", systemId],
    queryFn: () => getFieldValueGroupMappings(systemId),
    enabled: !!dependencyObj
  });
};

export const useGetFieldValueGroupMappingsHistory = (groupId: number, dependencyObj: any) => {
  return useQuery<IFieldValueGroupMappingHistory[], AxiosError>({
    queryKey: ["fieldValueGroupMappingHistory-info", groupId],
    queryFn: () => getFieldValueGroupMappingsHistory(groupId),
    enabled: !!dependencyObj
  });
};

export const useGetFieldValueGroupMappingsForSystems = (systemIds: Array<number>, dependencyObj: any) => {
  return useQueries(systemIds.map((systemId: number) => {
    return {
      queryKey: ['fieldValueGroupMapping-info', systemId],
      queryFn: () => getFieldValueGroupMappings(systemId),
      enabled: !!dependencyObj
    }
  }));
};

import { AccessorKeyColumnDef } from "@tanstack/react-table";
import { IFieldValueGroupMappingRow, IFieldValueGroupMapping } from "../models/models";


export const getColumnDefinition = (
  mappingsGroup: Map<string, Map<Number, IFieldValueGroupMappingRow>>,
  selectedMappingGroup: string
) => {
  let sourceColDef: AccessorKeyColumnDef<any>[] = [];
  let targetColDef: AccessorKeyColumnDef<any>[] = [];

  const selectedMappings = mappingsGroup.get(selectedMappingGroup);
  const row: IFieldValueGroupMappingRow | undefined = selectedMappings?.values().next().value;

  const srcFieldNames = row?.fromGroupMappings.map(m => m.fieldName) ?? [];
  srcFieldNames.sort((a: any, b: any) => a.localeCompare(b));

  const targetFieldNames = row?.toGroupMappings.map(m => m.fieldName) ?? [];
  targetFieldNames.sort((a: any, b: any) => a.localeCompare(b));

  srcFieldNames.forEach((field) => {
    if(field) {
      const srcCol = { header: field, accessorKey: field };
      sourceColDef.push(srcCol);
    }
  });

  targetFieldNames.forEach((field) => {
    if(field) {
      const targetCol = { header: field, accessorKey: field };
      targetColDef.push(targetCol);
    }
  });

  return { sourceColDef, targetColDef };
};

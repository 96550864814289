import axios, { AxiosError } from "axios";
import { System } from "../models/models";
import { useQuery } from 'react-query';
import { getApiConfig } from "./apiConfig";

const getSystems = async (excludeSystem: string): Promise<System[]> => {

  const config = getApiConfig();
  try {
    const response = await axios.get(`${config.url}/systems`, config.axiosConfig);

    if (excludeSystem) {
      return response?.data.filter((item: System) => item.name.toLowerCase() !== excludeSystem.toLowerCase()) ?? null;
    } else {
      return response?.data ?? null;
    }
    
  } catch (err) {
    console.error('err', err);
    throw err;
  }
};

export const useGetSystems = (excludeSystem: string) => {
  return useQuery<System[], AxiosError>(
    [`system-info`],
    () => getSystems(excludeSystem),
  );
};


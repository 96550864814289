import { Dictionary } from "lodash";
import { IFieldValue } from "../../../models/models";

const generateFieldOptions = (fields: Dictionary<IFieldValue[]> | undefined, systemId: number, exclude: string[]) => {
    if (fields) {
        let options = new Set<string>();
        for (const key in fields) {
            if (fields[key] && fields[key].length > 0 && fields[key][0].systemId === systemId) {
                options.has(key) === false && options.add(key);
            }
        }

        const items = [ ... options.keys()].map((option: string) => { return { label: option, value: option };});

        if(exclude){
            return items.filter((item) => !exclude.includes(item.label));
        }

        return items;
    }

    return [];

};


export default generateFieldOptions;
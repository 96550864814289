import axios from "axios";
import { getApiConfig } from "./apiConfig";
import { IGroupMapping } from "../models/models";
import { useMutation } from "react-query";

const updateGroupMapping = async (updates: Array<IGroupMapping>) : Promise<any> => {
    const config = getApiConfig();
    try {

        const response = await axios.put(`${config.url}/group-mappings`, updates, config.axiosConfig );
        return response?.data ?? null;

    } catch (err) {
        console.log(err);
        throw(err);
    }
}

export const useUpdateGroupMapping = () => {
    return useMutation({ 
        mutationFn: ({updates}: {updates: Array<IGroupMapping>}) => updateGroupMapping(updates),
        onSuccess: () => {},
        onError: (err) => { console.log(err); } 
    });
};
import axios from "axios";
import { IField } from "../models/models";
import { getApiConfig } from "./apiConfig";
import { useMutation } from "react-query";

const addField = async (field: IField) => {
    const config = getApiConfig();
    
    try {
        const response = await axios.put(`${config.url}/fields`,[field], config.axiosConfig);

        return response?.data ?? null;
    } catch (err) {
        console.log(err);
        throw (err);
    }
};

export const useAddField = () => {
    return useMutation({ 
        mutationFn: ({field}: {field: IField}) => addField(field),
        onSuccess: () => {},
        onError: (err) => { console.log(err); } 
    });
};
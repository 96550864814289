
import { System } from "../../../models/models";
import SpanElement from "./SpanElement";
import SelectedExchangeActions from "./SelectedExchangeActions";


const ExchangeCardElement = ({ dataSet }: { dataSet: System }) => {

  return (
    <>
      <div key={dataSet.id}>
        <div style={{ padding: "5px" }}>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}>
            <h3> {dataSet.name} </h3>
            <SelectedExchangeActions exchangeName={dataSet.name} exchangeId={dataSet.id} />
          </span>
          <section
            style={{ display: "flex", flexDirection: "row", columnGap: "30px", width: '280px', justifyContent: 'space-between' }}
          >
            <span>
              <SpanElement
                spanTitle="Mapping Groups"
                spanData={dataSet.totalMappingGroups.toLocaleString()} />
            </span>
          </section>
        </div>
      </div>
    </>
  );
};

export default ExchangeCardElement;

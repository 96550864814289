import ExchangeDetailsNavbar from "./ExchangeDetailsNavbar";
import { Alert, Breadcrumb, Sizes, Text } from "@sede-x/shell-ds-react-framework";
import { useParams } from "react-router-dom";
import getExchangeName from "../pages/Exchanges/utils/getExchangeName";
import getAlertType from "../utils/getAlertType"
import React from "react";

const ExchangeDetailsHeader = ({ isAlertHidden, setAlertHidden }:
  {
    isAlertHidden: { hidden: boolean, message: string, alertType: string },
    setAlertHidden: (arg: { hidden: boolean; message: string; alertType: string }) => void
  }) => {

  let { exchangeName, exchangeId } = useParams();

  const items = [
    {
      key: "1-1",
      name: "Dashboard",
      path: "/dashboard",
    },
    {
      key: "1-2",
      name: `${getExchangeName(exchangeName)}`,
      path: `/exchange-details/${exchangeName}/${exchangeId}`,
    },
  ];

  return (
    <>
      <div style={{ display: "flex", flexDirection: 'column', alignItems: "flex-start", justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "space-between", width: "100%" }}>

          <div style={{ display: "block", flexGrow: "0", flexShrink: "1", flexBasis: "auto" }}>
            <Breadcrumb separator="-" items={items} style={{ marginTop: '1.5rem', marginLeft: '1rem' }} />
            <div style={{ marginTop: '0.3rem', marginLeft: '1rem', marginBottom: '1.5rem' }}>
              <Text size="medium" bold={true}>{getExchangeName(exchangeName)}</Text>
            </div>
          </div>

          <div style={{ display: isAlertHidden.hidden ? "none" : "block", paddingRight: "3rem", paddingTop: "1.5rem", flexGrow: "0", flexShrink: "1", flexBasis: "auto", alignSelf: "auto" }}>
            <Alert onDismissClick={() => setAlertHidden({ hidden: !isAlertHidden.hidden, message: isAlertHidden.message, alertType: isAlertHidden.alertType })}
              hidden={isAlertHidden.hidden !== false}
              elevation
              style={{ minWidth: "450px" }}
              size={Sizes.Large}
              sentiment={getAlertType(isAlertHidden.alertType)}
              solidBgColor
              dismissible>{isAlertHidden.message}</Alert>
          </div>
        </div>
        <ExchangeDetailsNavbar />
      </div>
    </>
  );
};

export default ExchangeDetailsHeader;

import { useParams } from "react-router-dom";
import ExchangeDetailsHeader from "../../components/ExchangeDetailsHeader";
import { useAuth } from "oidc-react";
import isUserAdmin from "../../utils/isUserAdmin";
import { BaseTable, Card } from "@sede-x/shell-ds-react-framework";

const ExchangeInfo = () => {

    const { exchangeName, exchangeId } = useParams();

    const auth = useAuth();
    const isAdmin = isUserAdmin(auth.userData);

    const columns = [
        {
            header: 'Product',
            accessorKey: 'product'
        },
        {
            header: 'Market Area',
            accessorKey: 'marketArea'
        },
        {
            header: 'Description',
            accessorKey: 'description'
        }, {
            header: 'Timezone',
            accessorKey: 'timezone'
        }, {
            header: 'Trading Procedure',
            accessorKey: 'tradingProcedure'
        },
        {
            header: 'Trading Days',
            accessorKey: 'tradingDays'
        }, {
            header: 'EPEX Trading System',
            accessorKey: 'epexTradingSystem'
        }];


    const data = [
        {
            product: 'GB_Hour_Power', marketArea: '10YGB----------A', description: 'Hourly Intraday GB Continuous Power', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'GB_Half_Hour_Power', marketArea: '10YGB----------A', description: 'Half-Hourly Intraday GB Continuous Power', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'GB_2_Hour_Power', marketArea: '10YGB----------A', description: 'GB 2 Hour Continuous Power Block', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'GB_3_Plus_4', marketArea: '10YGB----------A', description: 'GB Blocks 3 & 4 covering hours 7 to 15', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'GB_4_Hour_Power', marketArea: '10YGB----------A', description: 'GB 4 Hour Continuous Power Block', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'GB_Baseload', marketArea: '10YGB----------A', description: 'GB Block baseload covering hours 1 to 24', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'GB_Extended_Peak', marketArea: '10YGB----------A', description: 'GB Block extended peakload covering hours 7 to 23', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'GB_Overnight', marketArea: '10YGB----------A', description: 'GB Block overnight covering hours 23 to 7', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'GB_Peakload', marketArea: '10YGB----------A', description: 'GB Block peakload covering hours 7 to 19', timezone: 'BST', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'Intraday_Hour_Power', marketArea: '10YCB-BE-------T', description: 'Hourly Intraday BE Continuous Power', timezone: 'CET', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'Intraday_Hour_Power', marketArea: '10YCH-SWISS1---B', description: 'Hourly Intraday CH Continuous Power', timezone: 'CET', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'Intraday_Hour_Power', marketArea: '10Y1001A1001A82H', description: '', timezone: 'CET', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        },
        {
            product: 'IIntraday_Hour_Power', marketArea: '10YFR-RTE-TEST-W', description: 'Hourly Intraday FR Continuous Power', timezone: 'CET', tradingProcedure: 'Continuous', tradingDays: 'Year-round', epexTradingSystem: 'M7'
        }
    ];

    if (auth.userData) {
        return (

            <>
                <div style={{ position: "relative", backgroundColor: "white" }}>
                    <ExchangeDetailsHeader isAlertHidden={{ hidden: true, message: '', alertType: '' }} setAlertHidden={() => { }} />

                    <div>
                        <p style={{ marginLeft: "5em", fontWeight: "bold", fontSize: "18px" }}>{exchangeName?.toLocaleUpperCase()}</p>
                    </div>
                    <div style={{ marginLeft: "5em" }}>
                        <p>
                            EPEX SPOT offers both continuous and auction trading in twelve European countries. All continuous Intraday markets of EPEX SPOT run on the M7 trading system (Comtrader).
                        </p>
                        <p>
                            <b>Tradable contracts:</b> hourly contracts, half-hourly contracts and 15-minute contracts
                        </p>
                        <p>
                            Intraday Continuous orders for GB and European markets are placed in one of the following ways
                        </p>
                        <p>
                            Orders are placed in the Epexspot’s ComTrader client
                            <ul>
                                <li> Algo orders via Exxeta </li>
                                <li> Orders to close out intraday positions via Likron </li>
                            </ul>
                        </p>

                    </div>

                    
                    <div style={{ paddingTop: "5em"}}>
                        <p style={{ marginLeft: "1em", fontWeight: "bold", fontSize: "18px" }}>Trade Products and Schedule</p>
                        <Card elevated={true} style={{ marginLeft: "1em", width: "1200px", height: "700px"}}>
                            <BaseTable data={data} maxWidth={1200} maxHeight={700} columns={columns} />;
                        </Card>
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default ExchangeInfo;

import { useState } from "react";
import { IFieldValueGroupMappingHistory } from "../../../models/models";
import { useGetFieldValueGroupMappingsHistory } from "../../../api/fieldValueGroupMappings";
import { BaseTable, IBaseTableProps } from "@sede-x/shell-ds-react-framework";
import { ExpandedState, getExpandedRowModel } from "@tanstack/react-table";

const HistoryChanges = ({toGroupId}: {toGroupId: number}) => {

    const { data, isLoading } = useGetFieldValueGroupMappingsHistory(toGroupId, true);

    const columns =  [
        {
          header: 'Field Name',
          accessorKey: 'fieldName',
        },
        {
          header: 'Field Value',
          accessorKey: 'fieldValue',
        },
        {
          header: 'Modified By',
          accessorKey: 'modifiedBy',
        },
        {
            cell: (info: any) => { 
                const dValue = info.getValue();
                const dd = new Date(dValue);
                return dd.toDateString();
            },
            header: 'Date updated',
            accessorKey: 'validFrom'
        }
      ];
    
      const [expanded, setExpanded] = useState<ExpandedState>(true);
    
      const tableOptions: IBaseTableProps<IFieldValueGroupMappingHistory>['tableOptions'] = {
        state: {
          expanded
        },
        onExpandedChange: setExpanded,
        getSubRows: (row: IFieldValueGroupMappingHistory) => row.historyChanges,
        getExpandedRowModel: getExpandedRowModel(),
      };
   
    if(isLoading) {
        return (<div>Loading ...</div>);
    }

    if(data) {
        return (
            <>
                <div style={{ paddingTop: "2rem"}}>
                    <BaseTable data={data} columns={columns} tableOptions={tableOptions}  />
                </div>
            </>
        );
    }

    return null;
};

export default HistoryChanges;
import { Select } from '@sede-x/shell-ds-react-framework';
import React from 'react';

export const SingleSelectField = ({
  label,
  value,
  onChange,
  disabled,
  optionValues,
}: {
  label: string;
  value: string | undefined;
  onChange: (val: string) => void;
  disabled: boolean;
  optionValues: Array<string> | undefined;
}) => {

  return (
    <>
      <div>
        <div>
          {label}
        </div>
        <Select
          placeholder="Select"
          style={{ minWidth: '500px' }}
          value={value}
          onChange={onChange}
          disabled={disabled}
          allowClear={false}
          optionFilterProp={'label'}
          optionLabelProp={'label'}
          options={optionValues?.map((x) => ({
            value: x,
            label: x,
          }))}
        />
      </div>
    </>
  );
};

import { BaseTable } from "@sede-x/shell-ds-react-framework";
import { System } from "../../../models/models";
import SelectedExchangeActions from "./SelectedExchangeActions"
import { ColumnDef, getSortedRowModel, SortingState } from '@tanstack/react-table';
import { QuestionCloud } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { EmptyStateIcon, Sizes, Loading } from "@sede-x/shell-ds-react-framework";
import { useState } from 'react';

const ExchangesTableView = ({ exchangesData, isLoading, isError }:
  {
    exchangesData: Array<System> | undefined,
    isLoading: boolean,
    isError: boolean
  }) => {

  const columns: ColumnDef<Partial<System>>[] =
    [
      { header: "Name", accessorKey: "name", enableSorting: true },
      { header: "Mappings", accessorKey: "totalMappingGroups", enableSorting: true },
      {
        header: "Actions", cell: ({ row }) => (
          <div style={{ display: 'block', textAlign: 'right'}}>
            <SelectedExchangeActions exchangeName={`${row.original.urlFriendlyName}`} exchangeId={row.original.id} />
          </div>
        ),
      }
    ];

  const [sorting, setSorting] = useState<SortingState>([]);
  const tableOptions = {
    state: {
      sorting
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel()
  };


  if (isError) {

    const icon: EmptyStateIcon = {
      component: <QuestionCloud width={128} height={128} />
    };
    return (
      <>
        <BaseTable columns={columns} data={[]} disableRowHover emptyStateProps={{
          size: Sizes.Medium,
          icon: icon,
          title: 'Data not retrieved',
          description: 'Something went wrong, please try again'
        }} style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 5px, rgba(0, 0, 0, 0.1) 0px 0px 2px' }} />
      </>
    );

  }

  if (isLoading) {
    const icon: EmptyStateIcon = {
      component: <Loading size={Sizes.Medium} />
    };
    return (
      <>
        <BaseTable columns={columns} data={[]} disableRowHover emptyStateProps={{
          size: Sizes.Medium,
          icon: icon,
          title: 'Retrieving data...',
          description: 'Please wait'
        }} style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 5px, rgba(0, 0, 0, 0.1) 0px 0px 2px' }} />
      </>
    );
  }


  return (
    <>
      <BaseTable className="exchangesTable" columns={columns} data={exchangesData} tableOptions={tableOptions} disableRowHover 
        style={{ width: '100%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 5px, rgba(0, 0, 0, 0.1) 0px 0px 2px' }} />
    </>
  );
};

export default ExchangesTableView;

import { Link, useLocation } from "react-router-dom";
import { NavBar, Sizes } from "@sede-x/shell-ds-react-framework";
import { useAuth } from "oidc-react";
import readUserProfile from "../utils/readUserProfile";
import UserProfileDropdown from "./UserProfileDropdown";
import React from "react";

const GlobalHeader = () => {

  const { pathname } = useLocation();
  const auth = useAuth();

  const NavbarItems = [
    { label: "Dashboard", key: "1-1", path: '/dashboard' },
  ];

  const userProfile = readUserProfile(auth);

  const initials = userProfile ? userProfile.initials : "";

  return (
    <>
      <NavBar
        className="AppNavigation"
        style={{ height: '62px' }}
        items={NavbarItems}
        header={{ title: 'Trade Capture Platform', description: "Shell Energy" }}
        size={Sizes.Large}
        urlProps={{  }}
        itemRender={({ path, children }) => <Link style={{ fontWeight: 'bold' }} to={path}>{children}</Link>}
        avatarProps={{ abbreviation: initials, dropdownOverlay: <UserProfileDropdown userProfile={userProfile} /> }}
        selectedPath="/dashboard"
      />
    </>
  );
};

export default GlobalHeader;

import { AccessorKeyColumnDef } from "@tanstack/react-table";
import { MappingGroupDefault, MappingGroupValues, ValueItem } from "../../../models/models";

const getNewMappingColumnData = (sourceColDef: AccessorKeyColumnDef<any>[], targetDef: AccessorKeyColumnDef<any>[]) => {

    const sourceData = sourceColDef.map(column => {
        return { label: column.header?.toString() ?? "", value: "" } as ValueItem;
    });

    const targetData = targetDef.map(column => {
        return { label: column.header?.toString() ?? "", value: "" } as ValueItem;
    });

    return {
        mappingGroup: MappingGroupDefault(),
        sourceData: sourceData, 
        targetData: targetData
    } as MappingGroupValues;
};

export default getNewMappingColumnData;
import { IconCircleInfo } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";

const ConfirmModalHeader = ({headerText, fontColor}: { headerText: string, fontColor: string}) => {
    return (
        <>
            <div className="modal-header" style={{
                fontFamily: "Shell Font",
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "24px",
                letterSpacing: "0em",
                fontStyle: "normal",
                margin: "unset",
                color: "{fontColor}"
            }}>
                <IconCircleInfo width={24} height={24} fill={fontColor} style={{ verticalAlign: "top" }} />
                <span style={{ paddingLeft: "1rem", color: "{fontColor}" }}>{headerText}</span>
            </div>
        </>
    );
};

export default ConfirmModalHeader;
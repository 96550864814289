import { IFieldValueGroupMapping, IFieldValueGroupMappingRow } from "../../../models/models";

const updateMappingDataSet = (
                data: Map<string, Map<number, IFieldValueGroupMappingRow>>, 
                mapping: { fromGroupId: number; toGroupId: number; }, 
                mappingGroupKey: string, 
                trackerItems: Array<{label: string, value: string, index: number }>) => {
        
        const updates = new Array<IFieldValueGroupMapping>();
        const mappingDataSet = data.get(mappingGroupKey);
        
        if (mappingDataSet) {
            var keys = [ ...mappingDataSet.keys()];
            for (let key of keys) {
                if(key === Number(mapping.fromGroupId)){
                    const row = mappingDataSet.get(key);

                    if(row) {
                        const mappings = row.toGroupMappings
                        for(const trackerItem of trackerItems) {
                            const groupMapping = mappings.find(m => m.fieldName === trackerItem.label);
                            if (groupMapping) {
                                groupMapping.fieldValue = trackerItem.value;
                                updates.push(groupMapping);
                            }
                        }                        
                    }
                }
            }
        }

        return updates;
    };

export default updateMappingDataSet;
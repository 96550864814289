import { useState } from "react";
import { useGetSystems } from "../../api/systems";
import { SelectedView } from "../../models/models";
import ExchangesSubHeader from "./components/ExchangesSubHeader";
import ExchangesCardView from "./components/ExchangesCardView";
import ExchangesTableView from "./components/ExchangesTableView";


const Exchanges = () => {
  const systemNameToExclude = "Aligne";
  
  const [selectedView, setSelectedView] = useState<SelectedView>(SelectedView.Table);
  const [filterValue, setFilterValue] = useState<string>('');

  const { data, error, isLoading } = useGetSystems(systemNameToExclude);

  const filterData = () => {
    if (!data) return [];
    if (!filterValue || filterValue === '') return data;

    return data.filter((item) => {
      return item.name.toLowerCase().includes(filterValue.toLowerCase());
    });
  }

  return (
    <>
      <ExchangesSubHeader
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        setFilterValue={setFilterValue}
        totalExchanges={data ? data.length : 0}
      />
      <div style={{ margin: '20px' }}>
        {selectedView === SelectedView.Card
          ? (<ExchangesCardView exchangesData={filterData()} />)
          : (<ExchangesTableView exchangesData={filterData()} isError={error ? true : false} isLoading={isLoading} />)
        }
      </div>
    </>
  );
};

export default Exchanges;

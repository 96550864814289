import { UserManagerSettings } from "oidc-client-ts";

const oidcConfig = (): UserManagerSettings => {
    return {
        authority: process.env.REACT_APP_STS_AUTHORITY,
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: `${process.env.REACT_APP_CLIENT_ROOT}signin-callback.html`,
        response_type: 'code',
        scope: process.env.REACT_APP_CLIENT_SCOPE,
        post_logout_redirect_uri: "https://sso-dev.shell.com/idp/startSLO.ping",
        end_session_endpoint: "https://sso-dev.shell.com/idp/startSLO.ping",
        automaticSilentRenew: true,
        silent_redirect_uri: `${process.env.REACT_APP_CLIENT_ROOT}silent-renew.html`
    } as UserManagerSettings;
}

export const getOidcConfig = () => { return oidcConfig(); };
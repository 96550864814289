import { ShellThemeProvider } from "@sede-x/shell-ds-react-framework";
import StartPage from "./pages/StartPage";
import { QueryClient, QueryClientProvider } from "react-query";
import "./App.css"
import React from "react";
import { AuthProvider } from "oidc-react";
import { getOidcConfig } from "./api/oidcConfig";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });

  const config = getOidcConfig();

  const oidcConfig = {
    authority: config.authority,
    clientId: config.client_id,
    redirectUri: config.redirect_uri,
    responseType: config.response_type,
    scope: config.scope,
    automaticSilentRenew: config.automaticSilentRenew,
    silentRedirectUri: config.silent_redirect_uri,
  };

  return (
    <React.StrictMode>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <AuthProvider {...oidcConfig}>
            <ShellThemeProvider theme="light">
                <StartPage />
            </ShellThemeProvider>
          </AuthProvider>
        </QueryClientProvider>
      </div>
    </React.StrictMode>
  );
}

export default App;

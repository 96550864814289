import { Loading } from '@sede-x/shell-ds-react-framework';

export const FullPageLoader = () => {
  return (
    <>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Loading size='large' />
        <span>Loading....</span>
      </div>

    </>
  );
};

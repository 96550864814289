import { Stepper } from "@sede-x/shell-ds-react-framework";

const EditTableHeader = (currentStep: number) => {
    const current = currentStep ?? 0;

    return (
        <>
            <Stepper type="base" className="editTableStepper" style={{ paddingBottom: "0px"}} current={current || 0}>
                <Stepper.Step order={1} title="Edit Target Fields" />
                <Stepper.Step order={2} title="Review Mapping" />
            </Stepper>
            <hr />
        </>
    );
};

export default EditTableHeader;
import {
  Button,
  Popover,
  Positions,
  Text,
  Variants,
} from "@sede-x/shell-ds-react-framework";
import {
  BarChart,
  Ellipsis,
  InfoCircle,
  Time,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import classes from "../../../assets/css/list-style.module.css";
import { useNavigate } from "react-router-dom";

const ActionList = ({ link, systemId }: { link: string; systemId: number | undefined }) => {

  const { listStyle, ulContainer } = classes;
  const navigate = useNavigate();

  return (
    <>
      <ul className={ulContainer}>
        <li className={listStyle}>
          <BarChart style={{ marginLeft: "15px" }} />
          <Text
            style={{ marginLeft: "4px" }}
            onClick={() => {
              navigate(`/exchange-details/${link}/${systemId}`);
            }}>
            View Exchange
          </Text>
        </li>
        <li className={listStyle}>
          <InfoCircle style={{ marginLeft: "15px" }} />
          <Text style={{ marginLeft: "4px" }}>View Exchange Info</Text>{" "}
        </li>
      </ul>
    </>
  );
};

const SelectedExchangeActions = ({ exchangeName, exchangeId }: { exchangeName: string; exchangeId: number | undefined; }) => {
  return (
    <>
      <Popover popup={<ActionList link={exchangeName} systemId={exchangeId} />}>
        <Button
          variant={Variants.Transparent}
          icon={<Ellipsis style={{ margin: "0px" }} />}
          iconPosition={Positions.Right}
        ></Button>
      </Popover>
    </>
  );
};

export default SelectedExchangeActions;

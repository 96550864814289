import { IFieldValueGroupMappingRow } from "../../../models/models";

const getColumnDuplicates = (selectedMappingGroupData: IFieldValueGroupMappingRow[]) => {
    const sourceColumns = selectedMappingGroupData[0].fromGroupMappings.map(m => m.fieldName).sort((a: string, b: string) => a.localeCompare(b));
    const targetColumns = selectedMappingGroupData[0].toGroupMappings.map(m => m.fieldName).sort((a: string, b: string) => a.localeCompare(b));

    const allCols = [...sourceColumns, ...targetColumns];
    const duplicates = allCols.filter((e, i, a) => a.indexOf(e) !== i);

    let duplicatesMap = new Map<string, string[]>();
    allCols.forEach((col, index) => {
      if (duplicates && duplicates.includes(col)) {
        let arr = duplicatesMap.get(col) ?? [];
        arr.push(index.toString());
        duplicatesMap.set(col, arr);
      }
    });

    return duplicatesMap;
};

export default getColumnDuplicates;
import { EmptyState } from "@sede-x/shell-ds-react-framework";
import AnonGlobalHeader from "../../components/AnonGlobalHeader";
import React from "react";


const NotAuthorized = () => {

    return (
        <>
            <AnonGlobalHeader />

            <div style={{paddingTop: "15rem"}}>
                <EmptyState title="Access is not allowed" 
                    description="You are not authorized to access this application. Please contact support@shell.com to request access."
                    image={<svg viewBox="0 0 104 104" fill="none" width="128px" height="128px"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M20.4108 96.1494H45.9828L46.8661 96.845C48.5118 97.9421 50.4355 98.5833 52.3333 98.5833C54.3982 98.5833 56.3536 97.9781 57.9367 96.7468L58.6836 96.1494H84.2407L86.6699 77.1441L101.314 66.5302L101.501 65.6722C102.181 62.5421 102.5 59.1797 102.5 55.5833C102.5 27.9001 80.0603 5.41666 52.3333 5.41666C24.6501 5.41666 2.16666 27.8563 2.16666 55.5833C2.16666 59.1797 2.48521 62.5421 3.16566 65.6722L3.3534 66.5358L18.1244 77.1441L20.4108 96.1494ZM47.5959 91.5509H24.4892L22.4486 74.5881L7.48502 63.8415C6.99779 61.2898 6.76518 58.5357 6.76518 55.5833C6.76518 30.3979 27.1878 10.0152 52.3333 10.0152C77.5187 10.0152 97.9015 30.4377 97.9015 55.5833C97.9015 58.5379 97.6686 61.2938 97.1806 63.8471L82.3608 74.588L80.1926 91.5509H57.0708L55.0888 93.1365C54.3796 93.6878 53.42 93.9848 52.3333 93.9848C51.4176 93.9848 50.3986 93.6536 49.4939 93.0693L47.5959 91.5509Z" fill="white"/>
                        <path d="M46.7892 93.8502H22.4499L20.2864 75.8661L5.41232 65.1838C4.73623 62.0738 4.46579 58.8286 4.46579 55.5833C4.46579 29.0804 25.9656 7.71588 52.3332 7.71588C78.836 7.71588 100.201 29.2157 100.201 55.5833C100.201 58.8286 99.9302 62.0738 99.2541 65.1838L84.5152 75.8661L82.2165 93.8502H57.8772L56.525 94.932C55.308 95.8785 53.8206 96.2841 52.3332 96.2841C50.8458 96.2841 49.3584 95.7433 48.1414 94.932L46.7892 93.8502Z" fill="#FBCF09"/>
                        <path fillRule="evenodd" clipRule="evenodd" d="M46.6568 93.7176H22.3174L20.1539 75.7336L5.27987 65.0513C4.60378 61.9412 4.33334 58.696 4.33334 55.4508C4.33334 28.9479 25.8331 7.58334 52.2007 7.58334C78.5684 7.58334 100.068 29.0831 100.068 55.4508C100.068 58.696 99.7977 61.9412 99.1216 65.0513L84.3828 75.7336L82.0841 93.7176H57.7447L56.3925 94.7994C55.1756 95.7459 53.6882 96.1516 52.2007 96.1516C50.7133 96.1516 49.2259 95.6107 48.009 94.7994L46.6568 93.7176ZM47.4683 85.3341H29.7547L27.9968 71.001L13.3932 60.4539C12.9876 58.8313 12.7171 57.0734 12.7171 55.3156C12.7171 52.476 13.258 49.6364 14.475 47.0673L40.3017 73.1645L15.2863 42.1994C16.2328 38.1428 18.3963 34.6272 21.5063 31.7876L43.9526 70.5953L24.0755 28.2719C26.6446 24.8914 30.2955 22.3223 34.3521 21.1053L48.1444 69.2431L38.1382 19.077C41.2482 17.3192 44.6287 16.5079 48.1444 16.5079C48.8205 16.5079 49.6318 16.5079 50.3079 16.6431L52.3362 68.7022L54.3644 16.6431C55.0405 16.5079 55.8518 16.5079 56.5279 16.5079C60.0436 16.5079 63.4241 17.4544 66.5341 19.077L56.5279 69.2431L70.3202 20.9701C74.3768 22.1871 78.0277 24.7562 80.5968 28.1367L60.5845 70.5953L83.0308 31.7876C86.1408 34.4919 88.3043 38.1428 89.2508 42.1994L64.2354 73.1645L90.0621 47.0673C91.2791 49.6364 91.82 52.476 91.82 55.3156C91.82 57.0734 91.5495 58.6961 91.1439 60.4539L76.5403 71.001L74.7824 85.3341H57.204L54.4996 87.2272C53.8236 87.7681 53.0122 87.9033 52.2009 87.9033C51.3896 87.9033 50.5783 87.6329 49.9022 87.2272L47.4683 85.3341Z" fill="#DD1D20"/>
                      </svg>} />
            </div>
        </>
    );
}

export default NotAuthorized;


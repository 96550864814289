import axios, { AxiosError } from "axios";
import { useQuery } from 'react-query';
import { IFieldValue } from "../models/models";
import { getApiConfig } from "./apiConfig";

export const getFieldValues = async (): Promise<IFieldValue[]> => {

  const config = getApiConfig();

  try {
    const response = await axios
      .get(`${config.url}/field-values`, config.axiosConfig);

    return response?.data ?? null;
  } catch (err) {
    console.error('err', err);
    throw err;
  }
};

export const useGetFieldValues = () => {
  return useQuery<IFieldValue[], AxiosError>(
    [`fieldValues-info`],
    () => getFieldValues(),
  );
};


import { getFieldValues } from "../api/fieldValues"
import * as _ from 'lodash';

export const getAllFields = () => {

   const promise = getFieldValues();

   let res = promise.then(data => {
      return _.groupBy(data, ({ fieldName }) => fieldName);
   })

   return res;
}
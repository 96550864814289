export const schedulesSampleData = [
  // {
  //   name: "TCP OMIE",
  //   time: [" DA : 13.00 ", " ID : 01:57, 04:57, 09:57, 15:07, 17:57, 21:57"],
  // },
  {
    name : 'EPEX M7',
    time : ['Live Continuous trades'],
  },
  {
    name: "NEXTRA",
    time: ["11:30, 15:00"],
  },
  {
    name: "EPEX",
    data: [
      {
        marketArea: "GB-NG-IDA2",
        auctionName: "PWR-SEM-GB-D",
        auctionResults: "8:30:00",
      },
      {
        marketArea: "GB-NG",
        auctionName: "PWR-MRC-D+1",
        auctionResults: "9:30:00",
      },
      {
        marketArea: "CH-SGD",
        auctionName: "PWR-CH-D+1",
        auctionResults: "10:10:00",
      },
      {
        marketArea: "FR-RTE",
        auctionName: "PWR-MRC-D+1",
        auctionResults: "11:45:00",
      },
      {
        marketArea: "BE-ELIA",
        auctionName: "PWR-MRC-D+1",
        auctionResults: "11:45:00",
      },
      {
        marketArea: "DE-AMP",
        auctionName: "PWR-MRC-D+1",
        auctionResults: "11:45:04",
      },
      {
        marketArea: "NL-TENNETNL",
        auctionName: "PWR-MRC-D+1",
        auctionResults: "11:45:00",
      },
      {
        marketArea: "NL-TENNETNL-15min",
        auctionName: "PWR-15-call-NL-D+1",
        auctionResults: "14:40:00",
      },
      {
        marketArea: "GB-NG-30min",
        auctionName: "PWR-30-call-GB-D+1",
        auctionResults: "15:45:00",
      },
      {
        marketArea: "GB-NG-IDA1",
        auctionName: "PWR-SEM-GB-D+1",
        auctionResults: "18:00:00",
      },
    ],
  },
  {
    name : 'N2EX',
    time : ['11:45, 15:00'],
  },
  // {
  //   name : 'ICE',
  //   time : ['Live Continuous trades'],
  // },
 
  // {
  //   name : 'Exxeta',
  //   time : ['No fixed schedule'],
  // }
];

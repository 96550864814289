import { System } from "../../../models/models";
import { Card, Grid } from "@sede-x/shell-ds-react-framework";
import { useNavigate } from "react-router-dom";
import { Button, Positions, Variants } from "@sede-x/shell-ds-react-framework";
import { ArrowRight } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import ExchangeCardElement from "./ExchangeCardElement";

const ExchangesCardView = ({ exchangesData }: { exchangesData: Array<System> | undefined; }) => {
  const navigate = useNavigate();

  return (
      <Grid columns={3}>
        {exchangesData?.map((dataSet) => {
          return (
            <Card elevated={true} footer={{
              content:
                <div style={{ backgroundColor: 'rgba(28, 28, 28, 0.04)', display: "grid", justifyContent: "flex-end", width: '100%', paddingRight: '30px'}}>
                  <Button
                    variant={Variants.Transparent}
                    iconPosition={Positions.Right}
                    icon={<ArrowRight />}
                    style={{ backgroundColor: "none", marginTop: '10px', marginBottom: '10px' }}
                    onClick={() => { navigate(`/exchange-details/${dataSet.name}/${dataSet.id}`); }} >
                    View details
                  </Button>
                </div>
            }}>
              <ExchangeCardElement dataSet={dataSet} />
            </Card>
          );
        })}
      </Grid>
  );
};

export default ExchangesCardView;

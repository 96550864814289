import {
  Button,
  Popover,
  Variants,
} from "@sede-x/shell-ds-react-framework";
import classes from "../../../assets/css/list-style.module.css";

const OptionsList = ({ handleOnNewMappingClick, handleOnEditTableClick }: {
  handleOnNewMappingClick: () => void;
  handleOnEditTableClick: () => void;
}) => {
  const { listStyle, ulContainer } = classes;

  return (
    <>
      <ul className={ulContainer}>
        <li className={listStyle}>
          <Button fullWidth variant={Variants.Transparent} onClick={handleOnNewMappingClick}>Add New Mapping</Button>
        </li>
        <li className={listStyle}>
          <Button fullWidth variant={Variants.Transparent} onClick={handleOnEditTableClick}>Edit Mapping Table</Button>
        </li>
      </ul>
    </>
  );
};

const MappingOptions = ({ handleOnNewMappingClick, handleOnEditTableClick, isPopupVisible, isAdmin }: {
  handleOnNewMappingClick: () => void;
  handleOnEditTableClick: () => void;
  isPopupVisible: boolean;
  isAdmin: boolean;
}) => {

  const optionsList = <OptionsList
    handleOnNewMappingClick={handleOnNewMappingClick}
    handleOnEditTableClick={handleOnEditTableClick} />

  if(!isAdmin) {
    return null;
  }
    
  return (
    <>
      <div style={{ position: "absolute", right: "0", paddingRight: "1rem" }}>
        <Popover popup={optionsList} popupVisible={isPopupVisible}>
          <Button style={{ width: "125px", paddingRight: "1rem" }}>Options</Button>
        </Popover>
      </div>
    </>
  );
};

export default MappingOptions;

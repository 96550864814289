import { Text } from "@sede-x/shell-ds-react-framework";

const SourceTargetBanner = () => {

    return (
        <>
            <div
                style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    borderRadius: "1.5rem",
                    border: "1px solid rgb(230, 245, 245)",
                    backgroundColor: "rgb(219,216,216)",
                    paddingTop: "1rem",
                    marginLeft: "1rem",
                    display: "inline-block",
                    verticalAlign: "bottom"
                }}
            ></div>
            <Text bold style={{ marginLeft: "1rem", display: "inline", verticalAlign: "top" }}>
                Source
            </Text>
            <div
                style={{
                    height: "1.5rem",
                    width: "1.5rem",
                    borderRadius: "1.5rem",
                    border: "1px solid rgb(230, 245, 245)",
                    backgroundColor: "white",
                    paddingTop: "1rem",
                    marginLeft: "1rem",
                    display: "inline-block",
                    verticalAlign: "bottom"
                }}
            ></div>
            <Text bold style={{ marginLeft: "1rem", display: "inline", verticalAlign: "top" }}>
                Target
            </Text>
        </>
    );
}


export default SourceTargetBanner;
import { NavBar, Sizes } from "@sede-x/shell-ds-react-framework";

const AnonGlobalHeader = () => {

    return (
        <>
            <NavBar
                className="AppNavigation"
                style={{ height: '62px' }}
                header={{ title: 'Trade Capture Platform', description: "Shell Energy" }}
                size={Sizes.Large}
            />
        </>
    );
};

export default AnonGlobalHeader;

import axios from "axios";
import { IFieldValueGroup } from "../models/models";
import { getApiConfig } from "./apiConfig";
import { useMutation } from "react-query";

const createMappingGroups = async (inserts: Array<IFieldValueGroup>): Promise<any> => {
    const config = getApiConfig();

    try {
        const response = await axios
            .put(`${config.url}/field-value-groups`,inserts, config.axiosConfig);

        return response?.data ?? null;
    } catch (err) {
        console.log(err);
        throw (err);
    }
};

export const useCreateMappingGroups = () => {
    return useMutation({ 
        mutationFn: ({inserts}: {inserts: Array<IFieldValueGroup>}) => createMappingGroups(inserts),
        onSuccess: () => {},
        onError: (err) => { console.log(err); } 
    });
};
import { Button, Sentiments, Variants } from "@sede-x/shell-ds-react-framework";
import { Database } from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { Text } from "@sede-x/shell-ds-react-framework";
import { System, ValueItem } from "../../../models/models";

const SelectNotFoundContent = ({ handleAddNewMapping, valueItem, system }:
    {
        handleAddNewMapping: (valueItem: ValueItem, system: System) => void,
        valueItem: ValueItem,
        system: System
    }) => {

    return (
        <>
            <div>
                <div style={{ paddingTop: "2em" }}>
                    <Database width={24} height={24} style={{ color: "black" }} />
                </div>
                <div style={{ paddingTop: "0.5em", paddingBottom: "1em" }}>
                    <Text size="medium">Mappping Not Found?</Text>
                </div>
                <div style={{ paddingBottom: "2.5em" }}>
                    <Button size="large" variant={Variants.Outlined} onClick={() => handleAddNewMapping(valueItem, system)} sentiment={Sentiments.Primary}>Add New Mapping</Button>
                </div>
            </div>
        </>
    );

};

export default SelectNotFoundContent;
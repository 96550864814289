import { IFieldValueGroupMappingRow, System } from "../models/models";

const getMappingGroups = (groupInfo: Map<string, Map<number, IFieldValueGroupMappingRow>>, selectedGroup: string) => {
    const selectedMappingGroupData = Array.from(groupInfo?.get(selectedGroup)?.entries() ?? []).map((row) => row[1]);
    const row = selectedMappingGroupData[0];

    const fromSystem = {
        id: row.fromGroupMappings[0].systemId,
        name: row.fromGroupMappings[0].systemName
    } as System;

    const toSystem = {
        id: row.toGroupMappings[0].systemId,
        name: row.toGroupMappings[0].systemName
    } as System;

    return { fromSystem, toSystem };
};


export default getMappingGroups;
import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { IGroupMapping } from "../models/models";
import { getApiConfig } from "./apiConfig";

export const getGroupMappings = async (): Promise<IGroupMapping[]> => {

  const config = getApiConfig();

  try {
    const response = await axios
      .get(`${config.url}/group-mappings`, config.axiosConfig);

    return response?.data ?? null;
  } catch (err) {
    console.error('err', err);
    throw err;
  }
};


export const useGetGroupMappings = () => {
  return useQuery<IGroupMapping[], AxiosError>(
    [`groupMapping-info`], () => getGroupMappings(),
  );
};


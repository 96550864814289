import { IFieldValueGroupMappingRow, MappingGroup, MappingGroupValues, System } from "../../../models/models";

const updateNewMappingModel = (model: MappingGroupValues, mappingGroupData: IFieldValueGroupMappingRow[]) => {

    const fromSystemId = mappingGroupData[0].fromGroupMappings[0].systemId;
    const fromSystemName = mappingGroupData[0].fromGroupMappings[0].systemName;

    const toSystemId = mappingGroupData[0].toGroupMappings[0].systemId;
    const toSystemName = mappingGroupData[0].toGroupMappings[0].systemName;

    const keyValues = model.sourceData.map((row) => row.value);
    const fromKeyValues = [fromSystemName, ... keyValues]
    const toKeyValues = [toSystemName, ... keyValues];

    const fromGroupName = fromKeyValues.join("_").replaceAll(" ", "_");
    const toGroupName = toKeyValues.join("_").replaceAll(" ", "_");

    model.mappingGroup = { 
        fromGroupId: -1,
        fromGroupName: fromGroupName, 
        fromSystem: {
            id: fromSystemId,
            name: fromSystemName
        } as System,
        toGroupName: toGroupName, 
        toGroupId: -1,
        toSystem: {
            id: toSystemId,
            name: toSystemName
        } as System
    };    

    return model;
};

export default updateNewMappingModel;
import { Dictionary } from "lodash";
import { IFieldValue } from "../../../models/models";

const generateFieldValueOptions = (selectedItem: {label: string, value: string}, allFieldValues: (Dictionary<IFieldValue[]> | undefined), systemId: number) => {
    if(selectedItem.label && allFieldValues){
        const options = allFieldValues[selectedItem.label]
        .filter(fieldValue => fieldValue.systemId == systemId)
        .map((fieldValue) => {
            return {label: fieldValue.value, value: fieldValue.value};
        });
        return options;
    }
    
    
    return [];
};

export default generateFieldValueOptions;
import axios from "axios";
import { getApiConfig } from "./apiConfig";
import { useMutation } from "react-query";

export const deleteMapping = async (fromGroupId: string, toGroupId: string): Promise<any> => {
    const config = getApiConfig();

    try {
        const response = await axios
            .delete(`${config.url}/field-value-groups?fromGroupId=${fromGroupId}&toGroupId=${toGroupId}`, config.axiosConfig);

        return response?.data ?? null;
    } catch (err) {
        console.log(err);
        throw (err);
    }
};

export const useDeleteMapping = () => {
    return useMutation({ 
        mutationFn: ({fromGroupId, toGroupId}: {fromGroupId: string, toGroupId: string}) => deleteMapping(fromGroupId, toGroupId),
        onSuccess: () => {},
        onError: (err) => { console.log(err); } 
    });
};


import { ButtonLink } from "@sede-x/shell-ds-react-framework";
import { UserProfile } from "../utils/readUserProfile";
import { useAuth } from "oidc-react";
import React from "react";

const UserProfileDropdown = ({ userProfile }: { userProfile: UserProfile | null}) => {

    const auth = useAuth();

    const handleLogOut = async () => {
        localStorage.clear();
        document.cookie.split(";").forEach(function (cookie) { 
            document.cookie = cookie.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); 
        });
        auth.signOut().then(() => window.location.href = process.env.REACT_APP_LOGOUT_URL ?? "");
    }

    if (userProfile) {

        return (
            <>
            <div style={{ padding: "8px 12px" }}>
                <div style={{ padding: "8px" }}>{userProfile.firstName} {userProfile.lastName}</div>
                <div style={{ padding: "8px" }}>{userProfile.email}</div>
                <hr />
                <div>
                    <ButtonLink href="#" onClick={handleLogOut}>Log Out</ButtonLink>
                </div>
            </div>
            </>
        );

    } else {
        return null;
    }
};

export default UserProfileDropdown;
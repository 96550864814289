import { Dispatch, SetStateAction } from "react";
import { SingleSelectField } from "../../../components/SingleSelectField";
import React from "react";

const MappingGroupDropdown = ({
  options,
  setMappingGroup,
  value,
}: {
  options: string[];
  setMappingGroup: (val: string) => void;
  value: string;
}) => {
  console.log(options);

  return (
    <>
      <SingleSelectField
        disabled={false}
        label=""
        onChange={setMappingGroup}
        value={value}
        optionValues={options}
      />
    </>
  );
};

export default MappingGroupDropdown;

import {
  Button,
  Popover,
  Positions,
  Variants,
  Popconfirm,
  Sizes,
  Sentiments,
} from "@sede-x/shell-ds-react-framework";
import {
  Ellipsis,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import classes from "../../../assets/css/list-style.module.css";
import { useRef, useState } from "react";
import { useAuth } from "oidc-react";
import isUserAdmin from "../../../utils/isUserAdmin";

const MappingActionList = ({
  handleViewClick,
  handleEditClick,
  handleDeleteClick
}: {
  handleViewClick: () => void;
  handleEditClick: () => void;
  handleDeleteClick: () => ({ successCallbackFn: () => void, cancelCallbackFn: () => void, errorCallbackFn: () => void });
}) => {
  const { listStyle, ulContainer } = classes;

  const container = useRef<HTMLDivElement>(null);
  const [showConfirmAlert, setShowConfirmAlert] = useState(false);


  const handleCancelAction = () => {
    setShowConfirmAlert(false);
    const result = handleDeleteClick();
    result.cancelCallbackFn();
  };

  const handleConfirmAction = () => {
    var result = handleDeleteClick();
    result.successCallbackFn();
  };

  const auth = useAuth();
  const isAdmin = isUserAdmin(auth.userData);

  const popconfirmTitle = <Popconfirm.Title text="Are you sure you want to delete this mapping?" />;
  const popconfirmDescription = <Popconfirm.Description text="You can not undo this action" />

  if(!isAdmin) {
    return (
      <>
      <div ref={container}>
        <ul className={ulContainer} style={{ marginLeft: "1rem" }}>
          <li className={listStyle}>
            <Button variant={Variants.Transparent} style={{ fontWeight: "normal" }} onClick={handleViewClick}>View Mapping</Button>
          </li>
        </ul>
      </div>
      </>
    );
  }

  return (
    <>
      <div ref={container}>
        <ul className={ulContainer} style={{ marginLeft: "1rem" }}>
          <li className={listStyle}>
            <Button variant={Variants.Transparent} style={{ fontWeight: "normal" }} onClick={handleViewClick}>View Mapping</Button>
          </li>
          <li className={listStyle}>
            <Button variant={Variants.Transparent} style={{ fontWeight: "normal" }} onClick={handleEditClick}>Edit Mapping</Button>
          </li>
          <li className={listStyle}>
            <Popconfirm size={Sizes.Large} title={popconfirmTitle} description={popconfirmDescription}
              sentiment={Sentiments.Negative} actions={[
                {
                  label: 'Cancel',
                  action: handleCancelAction,
                  props: { variant: Variants.Outlined }
                },
                {
                  label: 'Confirm',
                  action: handleConfirmAction
                }
              ]}
              style={{ maxWidth: '500px' }}
              dropdownProps={{
                popupVisible: showConfirmAlert,
                onPopupVisibleChange: setShowConfirmAlert,
              }}>
              <Button variant={Variants.Transparent} style={{ color: "red", fontWeight: "normal" }} >Delete Mapping</Button>
            </Popconfirm>
          </li>
        </ul>
      </div>
    </>
  );
};

const MappingActions = ({ handleViewClick, handleEditClick, handleDeleteClick }:
  {
    handleViewClick: () => void;
    handleEditClick: () => void;
    handleDeleteClick: () => ({ successCallbackFn: () => void, cancelCallbackFn: () => void, errorCallbackFn: () => void });
  }
) => {

  const [popupVisible, setPopupVisible] = useState(false);

  const mappingActionList = <MappingActionList handleViewClick={handleViewClick} handleEditClick={handleEditClick}
    handleDeleteClick={() => {
      setPopupVisible(false);
      return handleDeleteClick();
    }} />;

  return (
    <>
      <Popover popupVisible={popupVisible} onPopupVisibleChange={setPopupVisible} popup={mappingActionList}>
        <Button
          variant={Variants.Transparent}
          icon={<Ellipsis style={{ margin: "0px" }} />}
          iconPosition={Positions.Right} />
      </Popover>
    </>
  );
};

export default MappingActions;

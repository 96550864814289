import { BaseTable, Text } from "@sede-x/shell-ds-react-framework";
import { ISchedules } from "../models/SchedulesModel";

export const AllSchedulesView = ({schedulesData}: {schedulesData: ISchedules}) => {
  let columnsOthers = [
    { header: "Application", accessorKey: "name" },
    { header: "Time(approximately) BST", accessorKey: "time" },
  ];

  let columnsEpexEts = [
    { header: "Market Area", accessorKey: "marketArea" },
    { header: "Auction Name", accessorKey: "auctionName" },
    { header: "Auction Results", accessorKey: "auctionResults" },
  ];

  let EPEX = schedulesData.filter((schedule) => schedule.name === "EPEX")[0]?.data;
  let others = schedulesData.filter((schedule) => schedule.name !== "EPEX");
  
  return (
    <>
      <div style={{ margin: "20px", paddingTop: '20px' }}>
        <Text bold>EPX ETS Schedule</Text>
        <BaseTable
          columns={columnsEpexEts}
          data={EPEX}
          style={{ width: "95vw", marginTop: "20px" }}
        />
      </div>
      <div style={{ margin: "20px" }}>
        <Text bold>Other Schedules</Text>
        <BaseTable
          columns={columnsOthers}
          data={others}
          style={{ width: "95vw", marginTop: "20px" }}
        />
      </div>
    </>
  );
};

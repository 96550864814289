import { IFieldValueGroupMappingRow } from "../../../models/models";

const deleteFromMappingDataSet = (
    data: Map<string, Map<number, IFieldValueGroupMappingRow>>, 
    mapping: {fromGroupId: string, toGroupId: string}, 
    mappingGroupKey: string) => {

        const mappingDataSet = data.get(mappingGroupKey);
        if (mappingDataSet) {
            var keys = [ ...mappingDataSet.keys()];
            for (let key of keys) {
                if(key === Number(mapping.fromGroupId)){
                    mappingDataSet.delete(key);
                }
            }
        }

        return data;
};

export default deleteFromMappingDataSet;
import {
  ButtonGroup,
  TextInput,
  Heading,
  TextTypes
} from "@sede-x/shell-ds-react-framework";
import {
  GridSolid,
  ListSolid,
} from "@sede-x/shell-ds-react-framework/build/esm/components/Icon/components";
import { ChangeEventHandler, MouseEventHandler } from "react";
import { SelectedView } from "../../../models/models";

const ExchangesSubHeader = ({
  selectedView,
  setSelectedView,
  setFilterValue,
  totalExchanges,
}: {
  selectedView: SelectedView;
  setSelectedView: (arg: SelectedView) => void;
  setFilterValue: (arg: string) => void;
  totalExchanges: number;
}) => {

  const handleToggle = (selectedValue: string) => {
    setSelectedView(SelectedView[selectedValue as keyof typeof SelectedView]);
  };

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    setFilterValue(e.target.value);
  };
  const onClear: MouseEventHandler<HTMLButtonElement> = () => {
    setFilterValue('');
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "4.5em",
        }}
      >
        <Heading type={TextTypes.H2} style={{ marginLeft: '20px' }} bold>All Exchanges ({totalExchanges})</Heading>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            columnGap: "10px",
            width: "400px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextInput placeholder="Search Exchanges" clearable onChange={onChange} onClear={onClear} />
          <div style={{ width: "40%" }}>
            <ButtonGroup
              type="radio"
              selectedKey={selectedView}
              onChange={handleToggle}
              items={[
                {
                  label: "Table",
                  icon: <ListSolid />,

                },
                {
                  label: "Card",
                  icon: <GridSolid />,
                },
              ]}
              iconOnly
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ExchangesSubHeader;

import axios from "axios";
import { IFieldValue } from "../models/models";
import { getApiConfig } from "./apiConfig";
import { useMutation } from "react-query";

const addFieldValue = async (fieldValue: IFieldValue) => {
    const config = getApiConfig();

    try {
        const response = await axios
            .put(`${config.url}/field-values`,[fieldValue], config.axiosConfig);

        return response?.data ?? null;
    } catch (err) {
        console.log(err);
        throw (err);
    }
};

export const useAddFieldValue = () => {
    return useMutation({ 
        mutationFn: ({fieldValue}: {fieldValue: IFieldValue}) => addFieldValue(fieldValue),
        onSuccess: () => {},
        onError: (err) => { console.log(err); } 
    });
};
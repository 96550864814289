import { Text } from "@sede-x/shell-ds-react-framework";
import SourceTargetBanner from "./SourceTargetBanner";

const LookUpBanner = () => {
  return (
    <>
      <Text style={{ marginLeft: "2rem", display: "inline" }}>
        Look up for a list of Portfolios
      </Text>
      <SourceTargetBanner />
    </>
  );
};

export default LookUpBanner;

import {
    Text,
    Grid,
    TextInput,
    Select,
    Modal,
    Spacings,
    Sizes,
    Variants,
    Sentiments,
} from "@sede-x/shell-ds-react-framework";
import { ChangeEventHandler, MouseEventHandler, useContext, useState } from "react";
import { FieldContext } from "../../../Context";
import { IFieldValue, System, ValueItem } from "../../../models/models";
import { Dictionary } from "lodash";
import { useAddFieldValue } from "../../../api/addFieldValue";
import generateOptions from "../utils/generateOptions";
import SelectNotFoundContent from "./SelectNotFoundContent";
import ConfirmModalHeader from "./ConfirmModalHeader";
import React from "react";



const ViewItem = ({ label, value }: { label: string; value: string }) => {
    return (
        <>
            <div>
                <Text size="small" style={{ fontSize: "12px" }} bold={true}>
                    {label}
                </Text>
                <Text size="medium" bold={true}>{String(value)}</Text>
            </div>
        </>
    );
};

const EditItem = ({ item, index, system, allFields, onChange }:
    {
        item: ValueItem;
        index: number;
        system: System;
        allFields: Dictionary<IFieldValue[]>;
        onChange: (newValue: string, index: number) => void;
    }) => {

    const [isLoading, setIsLoading] = useState(false);
    const [enteredText, setEnteredText] = useState('');
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);


    const useAddFieldValueMutation = useAddFieldValue();

    const handleAddNewMapping = (item: ValueItem, system: System) => {
        setIsLoading(true);

        const model = {
            fieldName: item.label,
            value: enteredText,
            systemId: system.id,
            systemName: system.name,
            modifiedBy: 'system'

        } as IFieldValue;

        useAddFieldValueMutation.mutate({ fieldValue: model },
            {
                onSuccess: () => {
                    setIsLoading(false);
                    let fieldValues = allFields[item.label];
                    fieldValues.push(model);
                    onChange(enteredText, index);
                    setEnteredText('');
                    setSuccessModalOpen(true);
                }, onError: () => {
                    setIsLoading(false);
                    setEnteredText('');
                    setErrorModalOpen(true);
                }
            });
    };

    return (
        <>
            <div key={index}>
                <Text size="small" bold={true} style={{ fontSize: "12px" }}>
                    {item.label}
                </Text>

                <Select size="medium" allowClear optionFilterProp="value" optionLabelProp="label" loading={isLoading}
                    value={item.value} onSearch={setEnteredText}
                    options={generateOptions(item.label, allFields, system.id)}
                    onChange={val => onChange(val, index)}
                    notFoundContent={SelectNotFoundContent({ handleAddNewMapping, valueItem: item, system })}
                />
            </div>


            <Modal title={<ConfirmModalHeader headerText="Error" fontColor="rgb(221, 29, 33)" />}
                open={errorModalOpen} zIndex={1000}
                padding={Spacings.Tight}
                size={Sizes.Small} centered
                closable={false}
                actions={[{
                    props: { variant: Variants.Filled, sentiment: Sentiments.Negative },
                    label: "OK",
                    action: () => { setErrorModalOpen(false) }
                }
                ]} onClose={() => { }} >
                <p style={{
                    paddingTop: "1.5rem",
                    textAlign: 'center',
                    fontFamily: "Shell Font",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    fontStyle: "normal",
                    margin: "unset",
                    color: "rgb(221, 29, 33)"
                }}>There was an error adding new field value!</p>
            </Modal>

            <Modal title={<ConfirmModalHeader headerText="Success" fontColor="rgb(0, 133, 87);" />}
                open={successModalOpen} zIndex={1000}
                padding={Spacings.Tight} size={Sizes.Small}
                centered
                closable={false}
                actions={[{
                    props: { variant: Variants.Filled, sentiment: Sentiments.Positive },
                    label: "OK",
                    action: () => { setSuccessModalOpen(false) }
                }
                ]} onClose={() => { }} >
                <p style={{
                    paddingTop: "1.5rem",
                    textAlign: 'center',
                    fontFamily: "Shell Font",
                    fontWeight: "600",
                    fontSize: "20px",
                    lineHeight: "24px",
                    letterSpacing: "0em",
                    fontStyle: "normal",
                    margin: "unset",
                    color: "rgb(0, 133, 87)"
                }}>New field value has been added successfully!</p>
            </Modal>
        </>
    );
};

const FormItems = ({ formItems = [], editMode, lookupItems, system, setFormItems, trackerItems, setTrackerItems }:
    {
        formItems: Array<{ label: string; value: string }>,
        editMode: boolean,
        lookupItems: Dictionary<IFieldValue[]> | undefined,
        system: System,
        setFormItems: (arg: Array<{ label: string; value: string }>) => void,
        trackerItems: Array<{ label: string, index: number, value: string }>,
        setTrackerItems: (arg: Array<{ label: string, value: string, index: number }>) => void
    }) => {
    formItems = formItems ?? [];
    const allFields = lookupItems ?? {};

    const handleOnChange = (newValue: string, index: number) => {
        const newFormItems = [...formItems];
        const formItem = newFormItems[index];
        if (formItem) {
            formItem.value = newValue;
            setFormItems(newFormItems);

            const newTrackerItems = [...trackerItems];
            newTrackerItems.push({ label: formItem.label, value: newValue, index: index });
            setTrackerItems(newTrackerItems)
        }
    };


    return (
        <>
            <Grid columns="50% 50%" rowGap="1rem">
                {formItems && formItems.map((item, index) =>
                    editMode
                        ? <EditItem item={item}
                            index={index} system={system}
                            allFields={allFields} onChange={handleOnChange} />
                        : <ViewItem label={item.label} value={item.value} />
                )}
            </Grid>
        </>
    );
};

const DrawerForm = ({
    toSystem,
    drawerData,
    editMode,
    filterValue,
    setFilterValue,
    setFormItems,
    trackerItems,
    setTrackerItems
}:
    {
        toSystem: System,
        drawerData: { sourceData: { label: string, value: string }[], targetData: { label: string, value: string }[] },
        editMode: boolean,
        filterValue: string,
        setFilterValue: (arg: string) => void,
        setFormItems: (arg: Array<{ label: string; value: string }>) => void,
        trackerItems: Array<{ label: string, value: string, index: number }>,
        setTrackerItems: (arg: Array<{ label: string, value: string, index: number }>) => void
    }) => {

    const { sourceData, targetData } = drawerData;
    const fieldValues = useContext(FieldContext);

    const handleChange: ChangeEventHandler<HTMLInputElement> = e => {
        setFilterValue(e.target.value);
    };
    const handleClear: MouseEventHandler<HTMLButtonElement> = () => {
        setFilterValue('');
    };

    return (
        <>
            <div style={{ paddingTop: "2rem"}} >
                <div className="drawer-box" style={{ paddingBottom: "1.5rem" }}>
                    <TextInput placeholder="Search" clearable onChange={handleChange} value={filterValue} onClear={handleClear} />
                </div>
                <div className="drawer-box" style={{ backgroundColor: "rgb(245, 245, 245)", paddingLeft: "0", }}>
                    <Text bold={true} style={{ fontSize: "14px" }}>Source</Text>
                </div>

                <div style={{ marginBottom: "1rem" }}>
                    <div style={{ marginTop: "1rem" }}>{sourceData &&
                        <FormItems formItems={sourceData} lookupItems={{}} system={toSystem} editMode={false}
                            setFormItems={setFormItems}
                            trackerItems={trackerItems}
                            setTrackerItems={setTrackerItems} />}</div>
                </div>

                <div className="drawer-box" style={{ backgroundColor: "rgb(245, 245, 245)", paddingLeft: "0" }}>
                    <Text bold={true} style={{ fontSize: "14px" }}>Target</Text>
                </div>

                <div>
                    <div style={{ marginTop: "1rem" }}>{targetData && <FormItems
                        formItems={targetData} lookupItems={fieldValues}
                        system={toSystem}
                        editMode={editMode}
                        setFormItems={setFormItems}
                        trackerItems={trackerItems}
                        setTrackerItems={setTrackerItems} />}</div>
                </div>

            </div>

        </>
    );
};

export default DrawerForm;
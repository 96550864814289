const filterOutItems = (dataItems: Record<string, any>[], filterItems: string[] ) => {

    const bla = new Array<Record<string, any>>();
    for(const dataItem of dataItems){
        const copyItem  = {} as Record<string, any>;
        for(const key in dataItem){
            if(!filterItems.includes(key)){
                copyItem[key] = dataItem[key];
            }
        }
        bla.push(copyItem);
    }

    return bla;
};

export default filterOutItems;
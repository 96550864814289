import axios from "axios";
import { getApiConfig } from "./apiConfig";
import { useMutation } from "react-query";
import { IFieldValueGroupMapping } from "../models/models";

export const updateMapping = async (updates: Array<IFieldValueGroupMapping>): Promise<any> => {
    const config = getApiConfig();

    try {
        const response = await axios.put(`${config.url}/field-value-group-mappings`,updates, config.axiosConfig);
        return response?.data ?? null;
    } catch (err) {
        console.log(err);
        throw (err);
    }
};

export const useUpdateMapping = () => {
    return useMutation({ 
        mutationFn: ({updates}: {updates: Array<IFieldValueGroupMapping>}) => updateMapping(updates),
        onSuccess: () => {},
        onError: (err) => { console.log(err); } 
    });
};

